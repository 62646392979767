<template>
  <div >
     <!--篩選區塊-->
     <el-row>
       <el-button
          style="float: left;"
          size="small"
          type="primary"
          icon="el-icon-tickets"
          v-if="(list!=undefined&&list!=null&&list.length>0)&&(list==undefined||list==null||list.length<=0)"
          @click="exportReport()"
        >{{$t('ExportReport')}}
       </el-button>
   
       <div style="float: left;">
       <!--listAll Export to XLSX-->
       <excel-xlsx
         :data="listXls" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
         v-if="listXls!=undefined&&listXls!=null&&listXls.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/account-report/invoice','EXPORT','匯出充電樁請款紀錄統計報表 xlsx')">{{$t('DownloadAllDataXlsx')}}</el-button>
       </excel-xlsx>
       <!--listAll Export to XLSX-->
       </div>

       <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">請輸入發票開立日期:</span>
          <el-date-picker
             v-model="dateStart"
             size="small"
             class="filter-item"
             type="date"
             value-format="yyyy-MM-dd"
             :start-placeholder="listQuery.startOn"
             >
           </el-date-picker>
      </div>
      <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">收費管道:</span>
        <!--
        <el-input size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable /> -->
        <el-select size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable>
          <el-option
            v-for="item in platforms"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>

      
     </el-row>
     <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="listXls" >產製時間: {{ reportDate }} </span>
        </el-row>
     <!-- table -->
  

<div style= "margin: 50px 0;">
  
    <el-row> 
     <el-table
     :summary-method="getSummary" 
     show-summary
       size="mini"
       v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >     
       <!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="no" label="序號" />
       <el-table-column align="center" prop="customer_name" :show-overflow-tooltip="true"
                         :render-header="renderNameHeaderMethods"  label="客戶名稱" sortable>
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.customer_name != null" class="item" effect="dark" :content="scope.row.customer_name" placement="top-start">
            <span v-if="scope.row.customer_name != null">{{hideName(scope.row.customer_name)}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
       <el-table-column align="center" prop="hd_number"  label="客戶編號"/>
       <el-table-column align="center" prop="customer_id"  label="證號" :show-overflow-tooltip="true"  :render-header="renderAccountHeaderMethods"  >
       <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.customer_id" placement="top-start">
              <span>{{scope.row.customer_id.replace(scope.row.customer_id.substr(5,5),"*****")}}</span>
            </el-tooltip>
          </template>
     </el-table-column>
       <el-table-column align="center" prop="platform"  min-width="60px"  label="收費管道" /><!--替換:充電站-->
       <el-table-column align="center" prop="invoice_number" label="電信費收據/發票號碼" />  
       <el-table-column align="center" prop="deposit_amount" label="金額" />
       <el-table-column align="center" prop="charge" min-width="60px"  label="手續費" /><!--替換:充電站-->
       <el-table-column align="center" prop="settle_date" label="款項入帳日期" />
       <el-table-column align="center" prop="invoice_productname" label="品名" />
       <el-table-column align="center" prop="k2_code" label="會計契約編號" />
       <el-table-column align="center" prop="trade_institution" label="交易機構" />
        
     </el-table> 
     <el-divider content-position="center" >
      <span v-loading="listLoading" style="font-weight: bold; font-size: 1.2em;">合計金額:$  {{statisticsData.total_deposit_amount}}  元，手續費: $ {{statisticsData.total_charge}} 元</span>
    </el-divider>
     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
</el-row> 
  </div>
   </div>
 </template>
 
 <script>
 import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';
 /* 每隔10min刷新出jwt token*/
 
 
 export default {
     name: 'Status',
     components: {
         Pagination
     },
     mixins: [mixins],
     data() {
         return {
             dialogWidth: 0,
             total: 0,
             stationOption    : undefined,
             chargeOption     : undefined,
             connectorOption  : undefined,
             chargePointOption:undefined,
             platforms: [{
                value: 'Tap',
                label: 'TapPay'
              }, {
                value: 'Line',
                label: 'LinePay'
              }],
             list: [],
             statisticsData: '',
             listAll: [],
             listXls: [],
             listCsv: [],
             listLoading: false,
             listQuery: {
                 //serviceId: this.$store.state.currentAccount.id,
                 //serviceId: this.$store.state.currentAccount.serviceInfo.id,
                // stationId        : undefined,
                 //chargepointId    : undefined,
                 //chargeConnectorId: undefined,
                 startOn:undefined, // this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//30天前
                 platForm:undefined,
                 //endOn:  undefined,// this.$moment().format("YYYY-MM-DD"),
                 //queryDays: 31,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 15,
                 //sort: "customer_name,asc",
             },

             size           : undefined,
             paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
             dateStart: undefined,
             queryDays: undefined,
             dialogSettingVisible: false,
             targetUrl:undefined,
             reportDate: undefined,
             json_fields: {
               "No"   : "no",
               "StationName"     : "stationName",
               "PointName"   : "pointName",
               "Count" : "count",
               "UsageRate"     : "usageRate",
             },
             xlsxColumns : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '序號',
                     field: "no",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '客戶名稱',
                     field: "customer_name",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '客戶編號',
                     field: "customer_id",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '證號',
                     field: "hd_number",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '收費管道',
                     field: "platform",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '電信費收據/發票號碼',
                     field: "invoice_number",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '金額',
                     field: "deposit_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '手續費',
                     field: "charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '款項入帳日期',
                     field: "settle_date",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '品名',
                     field: "invoice_productname",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '會計契約編號',
                     field: "k2_code",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '交易機構',
                     field: "trade_institution",
                 },                 
                 ],
             /* 匯出檔名 */
             //filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls",
             //filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls",
             filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_InvoiceAll",
      
       // chartDataExist : false, // 是否有圖表資料
         //   xAxisData      : [],    // x軸 日期時間
           // yAxisData      : [],    // y軸  
           // y2AxisData     : [],    // y軸2        
         };
     },
   created() {
     //定時刷新
     //refreshToken();
 
     this.dialogWidth = this.setDialogWidth()
 
    // this.getStationByServiceId();
 
     
     //if(this.$route.params.stationId != undefined) 
    // {
     // this.chargePointOption = this.$route.params.stationId;   
    //}
     //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
     //this.resetPage(this.getList);
   },
   mounted() {
     window.onresize = () => {
       return (() => {
         this.dialogWidth = this.setDialogWidth()
       })()
     }
   },
   beforeDestroy() {
     console.log("Call stopRefreshToken");
     //stopRefreshToken();
   },
   watch: {
     paginationSelectedPage: {
       handler: function(newValue) {
         this.listQuery.page = newValue - 1
       }
     },

     /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
     '$i18n.locale': function(val) {
       console.log('Role組件重載前')
       this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
       console.log('Role組件重載完成')
       },
     /* ----------------------------------------------------------------------------- */
     dateStart: function(val) {
      if (val) {
        this.listQuery.startOn = this.dateStart
      } else {
        this.listQuery.startOn = undefined
      }this.list= [];
        this.statisticsData= '';
        this.listQuery.page = 0
        this.resetPage(this.getList)
        //this.getStatistics()
    }, 
    'listQuery.platForm': function (val) {
       this.listQuery.page = 0;
       this.listQuery.platForm = val;
       this.resetPage(this.getList);
       this.listXls= [];
       //this.connectorOption=null;
      },
      },
   computed: {
    
  },
   methods: {
    exportToCSV() {
    //  this.logOperate('report','/report/usage','EXPORT','匯出充電樁使用率統計報表 csv');

const csvContent = this.convertToCSV(this.listCsv); //utf-8 

const link = document.createElement("a");


// "\ufeff"是为了解决CSV中文
let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
let url = URL.createObjectURL(blob);
link.href = url;
link.setAttribute('download', this.filenameAllXlsx);
link.click();
},
convertToCSV(data) {
const columnDelimiter = ",";
const lineDelimiter = "\n";
const keys = Object.keys(data[0]);

let result = "";
result += keys.join(columnDelimiter);
result += lineDelimiter;

data.forEach(item => {
  let row = "";
  keys.forEach(key => {
    row += item[key] + columnDelimiter;
  });
  result += row.slice(0, -1);
  result += lineDelimiter;
});

return result;
},

     getList() {
       let number = this.page
       let size   = this.size

       this.listLoading = true

      this.targetUrl ="/api/v1/report/RequestPayment"; //?startOn=2024-07-28
 
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
         //this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         number = res.data.number
         size   = res.data.size
         /*
         this.listCsv = res.data.content
  
         this.listCsv =  this.listXls.map(( item , index ) => {
          item.no = index + number*size +1     
        return {
          編號: item.no,
          充電站: item.stationName,
          充電樁: item.pointName,
          使用次數: item.count,
          使用率: this.timeToSeconds(item.chargetime) + "("+item.chargetime+")"
        };
      });
      */
         /* 讓 this.list的Map加入一個key為no,另外若customer_id沒有值,則塞入沒有證號避免前端該筆出不來 */
         let output = this.list.map( ( item , index ) => {
             item.no = index + number*size +1
             if(!item.customer_id) item.customer_id = "沒有證號"           
             return item
         })         
       })

       this.targetUrl ="/api/v1/report/RequestPaymentTotal"; //?startOn=2024-07-28

this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
  this.statisticsData = res.data
 
  console.log(this.statisticsData)
})  
this.exportReport()
this.listLoading = false
     },

       exportReport(){

       this.size          = this.listQuery.size
       this.listQuery.page=0
       this.listQuery.size=100000
       let number = this.page
       let size   = this.size
       this.targetUrl ="/api/v1/report/RequestPayment"; //?startOn=2024-07-28
 

  this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
   this.listXls = res.data.content
   number = res.data.number
   size   = res.data.size



    /* 讓 this.list的Map加入一個key為no,另外若customer_id沒有值,則塞入沒有證號避免前端該筆出不來 */
    let output = this.listXls.map( ( item , index ) => {
      item.no = index + number*size +1
      if(!item.customer_id) item.customer_id = "沒有證號"           
      return item
  })  
   const newItem = {
        no: '總計',
        customer_name: '',
        customer_id: '',
        hd_number: '',
        platform: '',
        invoice_number: '',
        deposit_amount: this.statisticsData.total_deposit_amount,
        charge: this.statisticsData.total_charge,
        settle_date: '',
        invoice_productname: '',
        k2_code: '',
        trade_institution:'',
      };
      this.listXls.push(newItem);        
 })
       this.listQuery.size=this.size
       //this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
       //this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
       this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_InvoiceAll"
       //this.listQuery.size=15
     },

     getSummary({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小計';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
           const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if(index === 3){
            sums[index] = '';
          }
          //if(index === 6){
          //  sums[index] = this.statisticsData.total_deposit_amount;
         // }
         // if(index === 7){
          // sums[index] = this.statisticsData.total_charge;
          //}
          if(index === 8){
            sums[index] = '';
          }
          if(index === 9){
            sums[index] = '';
          }
          //sums[index] += ' 分';
          if(index === 11){
            sums[index] = '';
          }          
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
 },
}
 
 </script>
 